<template>
  <div class="recovery-third-step">
    <form class="recovery-third-step__form">
      <ui-input
          :label="$t('enter-new-password')"
          v-model="password"
          show-password
          :error="$v.password.$anyError"
          :error-text="getPasswordErrorText"
      />
      <ui-input
          :label="$t('confirm-new-password')"
          v-model="repeatPassword"
          show-password
          :error="$v.repeatPassword.$anyError"
          :error-text="getRepeatPasswordErrorText"
      />
    </form>
    <div class="recovery-third-step__line"></div>
    <div class="recovery-third-step__actions">
      <ui-button :disabled="disabled || $v.$anyError" @click="submit">
        {{ $t('change-password-1') }}
      </ui-button>
      <ui-button @click="$emit('back')" color="outline">
        {{ $t('cancel-1') }}
      </ui-button>

      <ui-alert
          v-model="showAlert"
      >
        <h5 class="ui-alert__title">
          {{ $t('password-changed-successfully') }}
        </h5>
        <UiButton @click="$emit('back')">
          {{ $t('continue') }}
        </UiButton>
      </ui-alert>
    </div>
  </div>
</template>


<script>
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import {mapActions} from "vuex";
export default {
  name: "PasswordRecoveryThirdStep",
  inject: ['form'],
  components: {
    UiInput: () => import("@/components/ui/UiInput"),
    UiButton: () => import("@/components/ui/UiButton"),
    UiAlert: () => import("@/components/ui/UiAlert"),
  },
  validations() {
    return {
      password: {
        required,
        minLength: minLength(8)
      },
      repeatPassword: {
        required,
        sameAs: sameAs('password'),
        minLength: minLength(8)
      }
    }
  },

  data() {
    return {
      password: '',
      repeatPassword: '',
      showAlert: false
    }
  },

  computed: {
    getPasswordErrorText() {
      if (!this.$v.password.minLength || !this.$v.password.required) {
        return this.$t('error-short-password')
      }

      return ''
    },
    getRepeatPasswordErrorText() {
      if (!this.$v.repeatPassword.minLength || !this.$v.repeatPassword.required) {
        return this.$t('error-short-password')
      } else if (!this.$v.repeatPassword.sameAs) {
        return this.$t('error-password-do-not-match')
      }

      return  ''
    },
    disabled() {
      return !this.password || !this.repeatPassword
    }
  },

  mounted() {
    document.addEventListener('keydown', this.enter)
  },

  // destroyed() {
  //   document.removeEventListener('keydown',  this.enter, false);
  // },

  methods: {
    ...mapActions(['reset']),
    submit() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.reset({
          email: this.form.email,
          authCode: this.form.authCode,
          password: this.password
        }).then(() => {
          this.showAlert = true
        })
      }
    },
    enter(e) {
      if(e.keyCode === 13) {
        this.submit()
      }
    },
  }
}
</script>


<style lang="scss" scoped>
.recovery-third-step {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__line {
    border-bottom: 1px solid #FFFFFF;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 20px
  }
}
</style>
